<template>
    <div class="content-container py-5">

        <div class="channels">
            <div v-for="channel of $root.teamspeak_channels" :key="channel.cid" :class="['channel', channel.compiled.class, {'full': (channel.channelMaxclients !== -1 && channel.channelMaxclients <= channel.totalClients), 'password': channel.channelFlagPassword}]">
                <div class="channel-name" :style="{paddingLeft: `${channel.childLevel*1+1}rem`}">
                    <Icon v-if="!channel.compiled.spacer" :data="icon.channel" class="me-1"></Icon>
                    <span class="name">{{channel.compiled.name}}</span>
                </div>
                <div v-if="channel.clients.length > 0" class="clients">
                    <div v-for="client of channel.clients" :key="client.clid" :style="{paddingLeft: `${channel.childLevel*1+2}rem`}" :set="client_loyalty = getClientLoyaltyLevel(client)" :class="getClientClasses(client)">
                        <Icon :data="getClientIcon(client)" class="me-2"></Icon>
                        <img :src="`/img/${client.clientServergroups.includes('8')? 'unverified_32x32.png' : 'verified_32x32.png'}`" class="client-status">
                        <div class="client-name">
                            {{client.clientNickname}}
                        </div>
                        <img v-if="client_loyalty !== false" :src="`/img/loyalty/lvl_${client_loyalty}.png`" :alt="`Loyalty Level ${client_loyalty}`" class="client-loyalty">
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="action-container">
        <div class="actions">
            <a :href="$root.connect_url" class="me-2">
                <button class="btn mb-2">
                    <Icon :data="icon.teamspeak" viewBox="0 0 512 512"/>
                    Join TeamSpeak
                </button>
            </a>
            <a href="https://realness.app" target="_blank">
                <button class="btn blue">
                    <Icon :data="icon.realness"/>
                    Register
                </button>
            </a>
        </div>
    </div>
    <div class="action-footer"></div>
</template>

<script>
import Icon from "@/app/components/Icon";
export default {
    name: "Home",
    components: {Icon},
    data: () => ({
        client_group_classes: {
            '7': 'verified'
        },
        loyalty_groups: {
            '24': 21,
            '25': 20,
            '26': 19,
            '27': 18,
            '28': 17,
            '29': 16,
            '30': 15,
            '31': 14,
            '32': 13,
            '33': 12,
            '34': 11,
            '35': 10,
            '36': 9,
            '37': 8,
            '38': 7,
            '39': 6,
            '40': 5,
            '41': 4,
            '42': 3,
            '43': 2,
            '44': 1,
            '45': 0,
        }
    }),
    methods: {
        getClientClasses(client){
            const classes = ['client'];
            for(const groupId in this.client_group_classes){
                if(client.clientServergroups.includes(groupId)){
                    classes.push(this.client_group_classes[groupId]);
                }
            }
            return classes;
        },
        getClientIcon(client){
            if(client.clientAway){
                return this.icon.away;
            } else if(!client.clientOutputHardware || client.clientOutputMuted){
                return this.icon.headset_off;
            } else if(!client.clientInputHardware || client.clientInputMuted){
                return this.icon.microphone_off;
            }
            return this.icon.circle;
        },
        getClientLoyaltyLevel(client){
            for(const groupId of client.clientServergroups){
                if(groupId in this.loyalty_groups){
                    return this.loyalty_groups[groupId];
                }
            }
            return false;
        }
    }
}
</script>

<style>
.content-container{
    width: 60rem;
    max-width: 100%;
    margin: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

.action-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1rem;
    background-color: black;
}
.action-container{
    position: fixed;
    bottom: 0;
    left: calc(50% - (min(100%, 35rem) / 2));

    width: 35rem;
    max-width: 100%;

    padding: 0 2rem;
}
.actions{
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    padding: 2rem 1rem 1.5rem;
    background-color: black;
    border-top-left-radius: 5rem;
    border-top-right-radius: 5rem;
}
.actions::after{
    content: "";
    position: absolute;
    bottom: 1rem;
    right: -1.2rem;
    width: 1.2rem;
    height: 1.2rem;

    border-radius: 0 0 0 0.6rem;
    box-shadow: -0.6rem 0 0 #000000;
}
.actions::before{
    content: "";
    position: absolute;
    bottom: 1rem;
    left: -1rem;
    width: 1.2rem;
    height: 1.2rem;

    border-radius: 0 0 0.6rem 0;
    box-shadow: 0.6rem 0 0 0 #000000;
}

    .channels{
        background-color: #101010;
        border: 0.1rem solid rgba(255,255,255,0.1);
        border-radius: 0.5rem;
        font-size: 0.8rem;
        padding: 0.5rem;
    }
    .channel{
        position: relative;
    }
    .channel .channel-name{
        display: flex;
        align-items: center;

        color: var(--lignt-gray);

        padding: 0.2rem 0.5rem;
        min-height: 0.8rem;

        border-radius: 0.3rem;
    }
    .channel .channel-name > .name{
        display: inline-block;
        width: 100%;
        overflow: hidden;
    }
    .channel.cspacer{
        text-align: center;
    }
    .channel.password .channel-name .icon{
        color: rgba(220, 220, 70, 1);
    }
    .channel.full .channel-name .icon{
        color: rgba(220, 70, 70, 1);
    }

    .channel .clients{

    }
    .channel .clients .client{
        display: flex;
        align-items: center;
        padding: 0.2rem 0.5rem;
        border-radius: 0.3rem;
    }
    .channel .clients .client .client-status{
        width: 0.8em;
        height: 0.8em;
        margin-right: 0.2rem;
    }
    .channel .clients .client .client-loyalty{
        height: 1em;
        width: 1em;
        margin-left: 0.4rem;
    }

    .channel .clients .client{
        display: flex;
        color: white;
    }
    .channel .clients .client.verified{
    }

    .channel .channel-name:hover,
    .channel .clients .client:hover{
        background-color: rgba(10, 140, 220,0.15);
    }
</style>