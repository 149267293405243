import { createWebHistory, createRouter } from "vue-router";

import Home from "@/app/page/Home";

const routes = [
    {
        path: '/', name: 'Home', component: Home,
        meta: {
            title: 'Get your free perma Channel on Realtalk.de TeamSpeak',
            metaTags: [
                {
                    name: 'description',
                    content: 'Simple TeamSpeak 3 Server with free perma channel for registered users.'
                },
                {
                    property: 'og:description',
                    content: 'Simple TeamSpeak 3 Server with free perma channel for registered users.'
                }
            ]
        }
    },
    { path: "/connect", redirect: { name: 'Home', query: { connect: true } } },
    { path: "/:any(.*)", redirect: { name: 'Home' } }
];

export default createRouter({
    history: createWebHistory(),
    routes
});
