import {mdiCircleOutline, mdiMicrophoneOff, mdiHeadsetOff} from "@mdi/js";

const mixin = {
    data: () => ({
        mounted: false,
        icon: {
            channel: 'M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z',
            circle: mdiCircleOutline,
            microphone_off: mdiMicrophoneOff,
            headset_off: mdiHeadsetOff,
            away: 'M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z',
            teamspeak: 'M244.2 346.79c2.4-12.3-12-30-32.4-48.7-20.9-19.2-48.2-39.1-63.4-46.6-21.7-12-41.7-1.8-46.3 22.7-5 26.2 0 51.4 14.5 73.9 10.2 15.5 25.4 22.7 43.4 24 11.6.6 52.5 2.2 61.7-1 11.9-4.3 20.1-11.8 22.5-24.3zm205 20.8a5.22 5.22 0 0 0-8.3 2.4c-8 25.4-44.7 112.5-172.1 121.5-149.7 10.5 80.3 43.6 145.4-6.4 22.7-17.4 47.6-35 46.6-85.4-.4-10.1-4.9-26.69-11.6-32.1zm62-122.4c-.3-18.9-8.6-33.4-26-42.2-2.9-1.3-5-2.7-5.9-6.4A222.64 222.64 0 0 0 438.9 103c-1.1-1.5-3.5-3.2-2.2-5 8.5-11.5-.3-18-7-24.4Q321.4-31.11 177.4 13.09c-40.1 12.3-73.9 35.6-102 67.4-4 4.3-6.7 9.1-3 14.5 3 4 1.3 6.2-1 9.3C51.6 132 38.2 162.59 32.1 196c-.7 4.3-2.9 6-6.4 7.8-14.2 7-22.5 18.5-24.9 34L0 264.29v20.9c0 30.8 21 50.4 51.8 49 7.7-.3 11.7-4.3 12-11.5 2-77.5-2.4-95.4 3.7-125.8C92.1 72.39 234.3 5 345.3 65.39 411.4 102 445.7 159 447.6 234.79c.8 28.2 0 56.5 0 84.6 0 7 2.2 12.5 9.4 14.2 24.1 5 49.2-12 53.2-36.7 2.9-17.1 1-34.5 1-51.7zm-159.6 131.5c36.5 2.8 59.3-28.5 58.4-60.5-2.1-45.2-66.2-16.5-87.8-8-73.2 28.1-45 54.9-22.2 60.8z',
            realness: 'M22.25,12.78a1,1,0,0,0,0-1.56L20.5,9.81a1,1,0,0,1-.33-1l.58-2.17a1,1,0,0,0-.91-1.25l-2.25-.12a1,1,0,0,1-.88-.64l-.8-2.1A1,1,0,0,0,14.43,2L12.54,3.24a1,1,0,0,1-1.08,0L9.57,2a1,1,0,0,0-1.48.48l-.8,2.1a1,1,0,0,1-.88.64l-2.25.12A1,1,0,0,0,3.25,6.6l.58,2.17a1,1,0,0,1-.33,1L1.75,11.22a1,1,0,0,0,0,1.56L3.5,14.19a1,1,0,0,1,.33,1L3.25,17.4a1,1,0,0,0,.91,1.25l2.25.12a1,1,0,0,1,.88.64l.8,2.1A1,1,0,0,0,9.57,22l1.89-1.23a1,1,0,0,1,1.08,0L14.43,22a1,1,0,0,0,1.48-.48l.8-2.1a1,1,0,0,1,.88-.64l2.25-.12a1,1,0,0,0,.91-1.25l-.58-2.17a1,1,0,0,1,.33-1Zm-4.9-3.3-6.79,6.8a.5.5,0,0,1-.71,0l-3.2-3.2a.5.5,0,0,1,0-.71l1.06-1.06a.5.5,0,0,1,.7,0l1.44,1.44a.51.51,0,0,0,.71,0l5-5a.48.48,0,0,1,.7,0l1.06,1.06A.48.48,0,0,1,17.35,9.48Z'
        }
    }),
    methods: {
        sleep(ms){
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        time(){
            return (new Date()).getTime()/1000;
        },
        alert(type, content, icon = null){
            this.$root.alert(type, content, icon);
        },
        random(min, max){
            return Math.floor((Math.random()*((max-min)+1))+min);
        },
        fallbackCopyTextToClipboard(text) {
            let textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                let successful = document.execCommand('copy');
                let msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        },
        copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(() => {
                console.log('Async: Copying to clipboard was successful!');
            }, err => {
                console.error('Async: Could not copy text: ', err);
            });
        },
        isPartOfElement(partElement, element){
            return this.isPartOfElements(partElement, [element]);
        },
        isPartOfElements(partElement, elements){
            while(true){
                if(elements.includes(partElement)) {
                    return true;
                } else if(!partElement.parentElement){
                    return false;
                } else {
                    partElement = partElement.parentElement;
                }
            }
        }
    },
    computed: {
        window: function(){
            return window;
        },
        console: function(){
            return console;
        }
    },
    mounted(){
        this.mounted = true;
    }
};

export default mixin;
