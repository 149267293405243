// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBPyled2boWSVzNLIdmY0-RfIhrrbH0vVQ",
    authDomain: "realness.firebaseapp.com",
    projectId: "realness",
    storageBucket: "realness.appspot.com",
    messagingSenderId: "599490081233",
    appId: "1:599490081233:web:bd95dbc31723a5695d70f0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {
    app,
    db,
};