<template>
    <router-view></router-view>

    <footer>
        <div class="copyright">© 2022 Realtalk.de  | All rights reserved</div>
    </footer>
</template>

<script>
import {db} from "@/firebase";
import {doc, onSnapshot} from "firebase/firestore";

export default {
    components: {},
    data: () => ({
        connect_url: 'ts3server://realtalk.de',
        pattern: /^\[([lrc\*]{1}spacer).*\](.*)$/,
        teamspeak_data: null,
        snapshot: {
            teamspeak: null
        },
        body: {
            width: 1920,
            height: 1080,
            scroll: 0
        },
    }),
    computed: {
        init: function(){
            return (this.teamspeak_data !== null);
        },
        teamspeak_channels: function(){
            if(this.teamspeak_data){
                const channels = Object.values(this.teamspeak_data);
                const ordered = [];
                const orderedIds = [];

                let next = 0;
                while(channels.length > ordered.length){
                    let next_before = next;
                    let is_child = false;

                    for(const channel of channels){
                        if(orderedIds.includes(channel.cid)) continue;

                        if(channel.channelOrder == 0 && channel.pid == next){
                            ordered.push(channel);
                            orderedIds.push(channel.cid);
                            next = channel.cid;
                            is_child = true;
                            break;
                        }
                    }

                    if(!is_child){
                        for(const channel of channels){
                            if(orderedIds.includes(channel.cid)) continue;

                            if(channel.channelOrder == next){
                                ordered.push(channel);
                                orderedIds.push(channel.cid);
                                next = channel.cid;
                                break;
                            }
                        }
                    }

                    if(next == next_before){
                        next = this.teamspeak_data[next].pid;
                    }
                }

                for(let i = 0; i < ordered.length; i++){
                    ordered[i].clients.sort((a, b) => {
                        if(b.clientTalkPower === a.clientTalkPower){
                            return (a.clientNickname > b.clientNickname) ? 1 : -1;
                        }
                        return b.clientTalkPower - a.clientTalkPower;
                    });

                    ordered[i].childLevel = this.getChannelChildLevel(ordered[i].cid);
                    ordered[i].compiled = this.getCompiledChannelData(ordered[i].channelName);
                }

                return ordered;
            }
            return [];
        }
    },
    watch: {
        init: function(init){
            if(init){
                const el = document.getElementById('loader-overlay');
                if(el){
                    el.parentElement.removeChild(el);
                }
            }
        }
    },
    methods: {
        getChannelChildLevel(cid){
            const channel = this.teamspeak_data[cid];
            return (channel.pid != 0)? this.getChannelChildLevel(channel.pid)+1 : 0;
        },
        getCompiledChannelData(name){
            const match = this.pattern.exec(name);

            let spacer = false;
            let classes = '';

            if(match){
                spacer = true;
                classes = match[1];
                name = match[2];

                if(match[1] === '*spacer' || this.isRepeatingChannel(name)){
                    classes = 'repeating';

                    for(let i = 0; i < 6; i++){
                        name += name;
                    }
                }
            }
            return {class: classes, name, spacer};
        },
        isRepeatingChannel(content){
            let is = true;
            if(content.length === 2 || content.length === 3){
                const chars = content.split('');
                const firstChar = chars[0];
                for(const char of chars){
                    if(char !== firstChar) {
                        is = false;
                        break;
                    }
                }
            } else {
                is = false;
            }
            return is;
        },
    },
    created() {
        const updateWindowSize = () => {
            this.body.width = window.innerWidth;
            this.body.height = window.innerHeight;
        }
        const updateWindowScroll = () => {
            this.body.scroll = window.scrollY;
        }

        // Scroll handler
        updateWindowScroll();
        document.addEventListener('scroll', updateWindowScroll);

        // Resize handler
        updateWindowSize();
        const observer = new ResizeObserver(updateWindowSize);
        observer.observe(document.body);

        this.snapshot.teamspeak = onSnapshot(doc(db, "applications", "realtalk"), (doc) => {
            this.teamspeak_data = doc.data().teamspeak;
        });
    },
    async mounted() {

        if('connect' in this.$route.query){
            const query = this.$route.query;
            delete query.connect;
            /*
            await this.$router.replace({
                ...this.$router.currentRoute,
                query
            });
            */

            document.location = this.connect_url;
        }

    }
}
</script>

<style>
footer{
    display: flex;
    justify-content: center;
    padding: 1rem 1rem 2rem;
    margin-bottom: 10rem;
}
footer .copyright{
    color: var(--gray);
    font-size: 0.8rem;
}
</style>
