<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24" class="icon" fill="currentColor">
        <path :d="data" />
    </svg>
</template>

<script>
export default {
    name: "Icon",
    props: {
        data: String
    }
}
</script>

<style>
    .icon{
        height: 1em;
        width: auto;
    }
</style>